<template>
	<iframe title="pdf预览" scrolling="no" style="width:100%;height:100%;border:none" :src="`/pdf/web/viewer.html?file=${pdfUrl}`"></iframe>
</template>
<script>
	export default {
		name: "appCommonPdf",
		props: {
			pdfUrl: {
				type: String,
				default: "",
			},
		},
		data() {
			return {};
		},
		computed: {
			url() {
				return this.pdfUrl;
			},
		},
		onLoad(option) {
			// if (option.pdfUrl) {
			// this.pdfUrl = decodeURIComponent(option.pdfUrl);
			//如果带参数，带数字还是用js解码一下
			// }
		},
	};
</script>