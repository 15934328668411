<template>
	<pdf :pdfUrl="url" />
	
</template>
<script>
	import pdf from "../../components/pdf/index.vue";

	export default {
		data() {
			return {
				url: "",
			};
		},
		mounted() {
			console.log(this.$route);
			this.url = encodeURIComponent(this.$route.query.pdf);
		},
		components: {
			pdf,
		},
	};
	document.addEventListener('keydown', function(event) {  
    // 检查是否按下了Ctrl+S  
    if (event.ctrlKey && event.key === 's') {  
        console.log("屏蔽了 Ctrl+S");
        // 如果你想要完全阻止事件传播，可以使用stopPropagation()  
        event.stopPropagation();  
		return false;
    }  
      
    // 在这里可以添加更多逻辑来处理其他按键  
});





</script>


